import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { Button, Dimmer, Header, Icon, Message, Segment, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  countryAtom,
  countriesAtom,
  currencyAtom,
  currenciesAtom,
  productsByIdAtom,
  showVatAtom,
  checkoutLoadingAtom,
  checkoutSuccessAtom,
} from "./state.js";
import { userAtom } from "./atoms/authAtom.js";
import { cartAtom } from "./atoms/cartAtom.js";
import { formattedPrice } from "./utils/price";
import useCancelCheckout from "./hooks/useCancelCheckout.js";
import usePrice from "./hooks/usePrice.js";
import SideCartRow from "./components/SideCartRow.js";
import CheckoutButton from "./components/CheckoutButton.js";

const SideCart = ({ width, visible, topMargin }) => {
  const [country] = useAtom(countryAtom);
  const [countries] = useAtom(countriesAtom);
  const [currency] = useAtom(currencyAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [productsById] = useAtom(productsByIdAtom);
  const [showVat] = useAtom(showVatAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const [checkoutLoading] = useAtom(checkoutLoadingAtom);
  const [checkoutSuccess] = useAtom(checkoutSuccessAtom);
  const [everythingInStock, setEverythingInStock] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [user] = useAtom(userAtom);
  const [vatString, setVatString] = useState("");

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { cancelCheckout } = useCancelCheckout();
  const { calculatedPrice } = usePrice();

  useEffect(() => {
    unstable_batchedUpdates(() => {
      // Figure out the VAT string
      if (country) {
        if (showVat) {
          setVatString(`${t("including")} ${countries[country]?.vat_percent}% ${t("vat")}`);
        } else {
          setVatString(`${t("excluding")} ${t("vat")}`);
        }
      } else {
        setVatString("");
      }

      // Is everything in stock?
      let inStock = true;
      let newQuantity = 0;
      cart?.items?.forEach((item) => {
        newQuantity += item.quantity;
        if (productsById[item.id]?.stock < item.quantity) {
          inStock = false;
        }
      });
      if (!inStock) {
        setEverythingInStock(false);
      } else {
        setEverythingInStock(true);
      }
      setTotalQuantity(newQuantity);
    });
  }, [cart, country, countries, currency, currencies, productsById, showVat, user, t]);

  useEffect(() => {
    // Calculate total
    let newTotal = 0;
    cart?.items?.forEach((item) => {
      newTotal += Number(calculatedPrice(productsById[item.id], item.quantity));
    });
    setTotal(newTotal);
  }, [cart, currency, currencies, productsById, showVat, user, calculatedPrice]);

  if (!cart?.items || cart?.items?.length === 0) {
    return (
      <Segment
        style={{
          display: visible ? "block" : "none",
          position: "fixed",
          width: width,
          maxWidth: width,
          right: "0px",
          top: topMargin,
          height: "100vh",
          marginTop: "0px",
          paddingTop: "2em",
          paddingBottom: "150px",
          paddingLeft: "0px",
          paddingRight: "0px",
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: 0,
          zIndex: 1000,
        }}
      >
        <Segment basic textAlign="center">
          <Icon name="shopping cart" size="huge" />
          <Header as="h3">{t("cart_is_empty")}</Header>
        </Segment>
      </Segment>
    );
  }

  if (cart.checkingOut && location?.pathname === "/checkout" && (checkoutLoading || checkoutSuccess)) {
    return (
      <Segment
        style={{
          display: visible ? "block" : "none",
          position: "fixed",
          width: width,
          maxWidth: width,
          right: "0px",
          top: topMargin,
          height: "100vh",
          marginTop: "0px",
          paddingTop: "2em",
          paddingBottom: "150px",
          paddingLeft: "0px",
          paddingRight: "0px",
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: 0,
          zIndex: 1000,
        }}
      >
        <Segment basic textAlign="center">
          <Message info icon>
            <Icon name="shopping cart" />
            <Message.Content>
              <Message.Header>Checking out</Message.Header>
              <Segment basic textAlign="center">
                <p>You can't change the cart while checking out.</p>
              </Segment>
              <Button negative icon="close" size="large" content="Abort Checkout" onClick={() => cancelCheckout()} />
            </Message.Content>
          </Message>
        </Segment>
      </Segment>
    );
  }

  console.log(i18n?.language);

  return (
    <Segment
      key={i18n?.language}
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        width: width,
        maxWidth: width,
        right: "0px",
        top: topMargin,
        height: "100vh",
        marginTop: "0px",
        paddingTop: "0px",
        paddingBottom: "150px",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflowY: "auto",
        overflowX: "hidden",
        borderRadius: 0,
        zIndex: 1000,
      }}
    >
      <Dimmer.Dimmable
        dimmed={cart.checkingOut && location?.pathname === "/checkout" && (checkoutLoading || checkoutSuccess)}
      >
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("product")}</Table.HeaderCell>
              <Table.HeaderCell>-</Table.HeaderCell>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>+</Table.HeaderCell>
              <Table.HeaderCell style={{ width: "6em" }}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {cart?.items?.map((item, index) => {
              return <SideCartRow key={index} item={item} />;
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={6} textAlign="right">
                <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  {formattedPrice(total, currencies[currency]?.symbol, currencies[currency]?.right_side) || "-"}
                </span>
                <br />
                <span style={{ fontSize: "0.8em", fontStyle: "italic" }}>{vatString}</span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {!everythingInStock && (
          <Message negative icon="exclamation triangle" header={t("items_have_insufficient_stock")} />
        )}
        <Segment basic textAlign="center">
          <CheckoutButton enabled={everythingInStock} contents={t("check_out")} />
        </Segment>
        <Dimmer
          inverted
          verticalAlign="top"
          active={cart.checkingOut && location?.pathname === "/checkout" && (checkoutLoading || checkoutSuccess)}
        >
          <Segment basic>
            <Message info icon>
              <Icon name="shopping cart" />
              <Message.Content>
                <Message.Header>Checking out</Message.Header>
                <p>You can't change the cart while checking out.</p>
                <Button
                  negative
                  icon="close"
                  size="large"
                  content={t("abort_checkout")}
                  onClick={() => cancelCheckout()}
                />
              </Message.Content>
            </Message>
          </Segment>
        </Dimmer>
      </Dimmer.Dimmable>
    </Segment>
  );
};

export default SideCart;
