import { useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAtom } from "jotai";
import {
  bundleFinishedAtom,
  fullBundleFinishedAtom,
  brandsAtom,
  countriesAtom,
  currenciesAtom,
  inventoryLocationsAtom,
  languagesAtom,
  priceListsAtom,
  productsAtom,
  productsByIdAtom,
  productsByUrlAtom,
  productGroupsAtom,
  productRelationsAtom,
  shipmentMethodsAtom,
  specialPriceListAtom,
  specialPricesAtom,
  suppliersAtom,
} from "../state.js";

const DATA_URL = "/bundle.json";

const useBundle = () => {
  const [, setBundleFinished] = useAtom(bundleFinishedAtom);
  const [, setFullBundleFinished] = useAtom(fullBundleFinishedAtom);
  const [, setBrands] = useAtom(brandsAtom);
  const [, setCountries] = useAtom(countriesAtom);
  const [, setCurrencies] = useAtom(currenciesAtom);
  const [, setInventoryLocations] = useAtom(inventoryLocationsAtom);
  const [, setLanguages] = useAtom(languagesAtom);
  const [, setPriceLists] = useAtom(priceListsAtom);
  const [, setProducts] = useAtom(productsAtom);
  const [, setProductsById] = useAtom(productsByIdAtom);
  const [, setProductsByUrl] = useAtom(productsByUrlAtom);
  const [, setProductGroups] = useAtom(productGroupsAtom);
  const [, setProductRelations] = useAtom(productRelationsAtom);
  const [, setShipmentMethods] = useAtom(shipmentMethodsAtom);
  const [, setSpecialPriceList] = useAtom(specialPriceListAtom);
  const [, setSpecialPrices] = useAtom(specialPricesAtom);
  const [, setSuppliers] = useAtom(suppliersAtom);

  useEffect(() => {
    const fetchStarterBundle = async () => {
      try {
        // Brands
        setBrands(arrayToMapWithTag(window.starterBundle.brands));

        // Countries
        setCountries(arrayToMapWithId(window.starterBundle.countries));

        // Currencies
        setCurrencies(arrayToMapWithId(window.starterBundle.currencies));

        // Languages
        setLanguages(arrayToMapWithId(window.starterBundle.languages));

        // Products
        setProducts(
          window.starterBundle.products.reduce((acc, item) => {
            if (item.url && item.part_number) {
              acc[item.url] = item;
            } else {
              // console.error('Product has no url:', item);
            }
            return acc;
          }, {}),
        );

        // Products By ID
        setProductsById(arrayToMapWithId(window.starterBundle.products));

        // Products By URL
        setProductsByUrl(
          window.starterBundle.products.reduce((acc, item) => {
            if (item.url && item.part_number) {
              acc[item.url] = item.id;
            } else {
              // console.error('Product has no url:', item);
            }
            return acc;
          }, {}),
        );

        // Product Groups
        setProductGroups(arrayToMapWithTag(window.starterBundle.product_groups));

        // Shipment Methods
        setShipmentMethods(arrayToMapWithId(window.starterBundle.shipment_methods));

        // Special Price List
        if (window.starterBundle.special_price_list) {
          setSpecialPriceList(window.starterBundle.special_price_list);
        }

        // Special Prices
        if (window.starterBundle.special_prices) {
          setSpecialPrices(window.starterBundle.special_prices);
        }

        setBundleFinished(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchBundle = async () => {
      try {
        // Sleep for 4 seconds to simulate a slow connection
        // await new Promise(resolve => setTimeout(resolve, 4000));

        const response = await fetch(DATA_URL + "?t=" + Date.now());
        const data = await response.json();

        // Products
        // data.products.forEach(product => {
        //   if (product.url && product.part_number) {
        //     setProducts({ [product.url]: product });
        //   } else {
        //     // console.error('Product has no url:', product);
        //   }
        // });

        // Inventory Locations
        setInventoryLocations(arrayToMapWithId(data.inventory_locations));

        // Price Lists
        setPriceLists(arrayToMapWithId(data.price_lists));

        // Products
        setProducts(
          data.products.reduce((acc, item) => {
            if (item.url && item.part_number) {
              acc[item.url] = item;
            } else {
              // console.error('Product has no url:', item);
            }
            return acc;
          }, {}),
        );

        // Products By ID
        setProductsById(arrayToMapWithId(data.products));

        // Products By URL
        setProductsByUrl(
          data.products.reduce((acc, item) => {
            if (item.url && item.part_number) {
              acc[item.url] = item.id;
            } else {
              // console.error('Product has no url:', item);
            }
            return acc;
          }, {}),
        );

        // Product Relations
        setProductRelations(data.product_relations);

        // Special Price List
        if (data.special_price_list) {
          setSpecialPriceList(data.special_price_list);
        }

        // Special Prices
        if (data.special_prices) {
          setSpecialPrices(data.special_prices);
        }

        // Suppliers
        setSuppliers(data.suppliers);

        setFullBundleFinished(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const arrayToMapWithId = (array) =>
      array?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

    const arrayToMapWithTag = (array) =>
      array?.reduce((acc, item) => {
        acc[item.tag] = item;
        return acc;
      }, {});

    fetchStarterBundle();
    fetchBundle();
  }, []);
};

export default useBundle;
